@charset "UTF-8";

$primary   : #00D4EA ;  // GS25
$secondary : #09E27C ; // GS THE FRESH & 우동마트
$tertiary  : #8F55FA ; // WINE25+
$accent    : #FF6A13 ;

/************
    Mix-in
*************/
/*Icon Size 24(기본),20,16 : */
@mixin _icon {
  content: '';
  display: inline-block;
  vertical-align: middle;
  background-size: 100%;
  background-repeat: no-repeat;
  font-size: 0;
  background-color: transparent;
  background-position: center center;
  width: 24px;
  height: 24px;
}

@mixin _boxShdow {
  border: 1px solid #e5e8f0;
  box-shadow: 0px 2px 4px rgba(6, 6, 6, 0.08);
  border-radius: 12px;
}

@mixin _tit {
  padding-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 18px;
  color: #060606;
}
@mixin _resetBtn {
  border:none;
  display: inline-block;
  height:auto;
  border-radius:0;
  background-color:transparent;
  width: auto;
}
@mixin flex-m {display: flex;	align-items: center; justify-content: center;}
@mixin _ellipsis2{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  //word-break: keep-all;
}
/* assets/images/ 하위 경로에 위치한 파일에 접근하는데 편의를 높이기 위함 */
@function asset($base, $name) {
  @return url($base + $name);
}
@function img-url($name) {
  $base: '../../public/images/';
  @return asset($base,$name);
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes fadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}
