@font-face {
    font-family: 'Pretendard';
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/Pretendard-Regular.woff2') format('woff2');
}
@font-face {
    font-family: 'Pretendard';
    font-weight: 500;
    font-style: normal;
    src: url('../fonts/Pretendard-Medium.woff2') format('woff2');
}
@font-face {
    font-family: 'Pretendard';
    font-weight: 600;
    font-style: normal;
    src: url('../fonts/Pretendard-SemiBold.woff2') format('woff2');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/Pretendard-Bold.woff2') format('woff2');
}

//@font-face {
//    font-family: 'Pretendard';
//    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
//    font-weight: 400;
//    font-style: normal;
//}
