@import "variables";
/* 기본  아코디언 */
.accordion__item + .accordion__item { }
.accordion__heading { padding: 16px 16px 17px 16px;
  .accordion__button { padding-right: 30px; }
 }
.accordion__button {
  position: relative;
  cursor: pointer;
  width: 100%;
  //margin-bottom:17px;
  text-align: left;
  //font-weight: 600;
  font-size: 15px;
  color:#1E2530;
  line-height: 1.5;
}
.accordion__button:after {
  content: '';
  position:absolute;
  @include _icon();
  top:0px ;
  right: 0px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.75736 11.2574L12 15.5L16.2426 11.2574' stroke='%23838A93' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  transition: all ease .4s 0s;
}
.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
  transform: rotate(-180deg);
}

[hidden] {
  height:0;
}
.accordion__panel {
  padding: 16px;
  font-weight: 500;
  color:#555D67;
  background-color: rgba(244, 245, 251, 0.6);
  transition: height 400ms linear 0s;
  height:auto;
}
