@charset "UTF-8";
/*Reset*/
body,
html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0 auto;
  background: #ffffff;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.4;
  color: #1e2530;
  word-wrap: break-word;
  //word-break: keep-all;
}
* {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
  "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
  "Malgun Gothic", sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  outline-color: transparent;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, textarea, p, blockquote, th, td, input, select, textarea, button {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #1e2530;
}

label,
button,
select {
  cursor: pointer;
}

legend,
caption,
.hide {
    visibility: hidden;
    overflow: hidden;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
}
dl,
li {
  list-style: none;
}

*:focus {
  outline: none;
}
input[type=button],
input[type=email],
input[type=text],
input[type=number],
input[type=password],
input[type=reset],
input[type=search],
input[type=submit],
input[type=tel],
select,textarea
{-webkit-appearance:none;-moz-appearance:none;appearance:none;resize: none;}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: #a5acb8;opacity: 1;
}
input[type=text]::-webkit-input-placeholder {
  color: #a5acb8;opacity: 1;
}
input[type=email]::-webkit-input-placeholder {
  color: #a5acb8;opacity: 1;
}
input[type=number]::-webkit-input-placeholder {
  color: #a5acb8;opacity: 1;
}

form,
fieldset,
img {
  border: 0 none;
}
img {
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
}

em, i ,
address {
  font-style: normal;
}

.safe-area-margin-top {
  margin-top: env(safe-area-inset-top);
}
.safe-area-margin-bottom {
  margin-bottom: env(safe-area-inset-bottom);
}
.safe-area-padding-top {
  padding-top: env(safe-area-inset-top);
}
.safe-area-padding-bottom {
  padding-bottom: env(safe-area-inset-bottom);
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

strong,
b {
  font-weight: 600;
}

.render-loading {
  //background-color: rgba( 255, 255, 255, 0.5 );
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  align-items: center;
  justify-content: center;
  z-index:1050;
}

//2022년 8월 2일 이황춘 추가.
.termsCont table {
   border: 1px solid #E5E8F0
 }

.termsCont tr {
  border: 1px solid #E5E8F0
}

.termsCont th {
  border: 1px solid #E5E8F0
}

.termsCont td {
  border: 1px solid #E5E8F0;
  padding: 12px 12px 12px 8px;
}

// 모달 css 재정의
.modal {
  --bs-modal-padding: 24px;
  --bs-modal-margin: 30px;
  --bs-modal-border-radius: 16px;
}
