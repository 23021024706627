@import "base";
@import "font";
@import "variables";

.color1 {
  color: $primary
}

.color2 {
  color: $secondary
}

.color3 {
  color: $tertiary
}

.point {
  color: $accent
}

/* Tag: 기본은 primary 그외 컬러는 Scope하여 사용 */
.tag {
  display: inline-block;
  padding: 1px 4px 0;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  border-radius: 3px;
  line-height: 19px;
  background: $primary;
}

/***************
    BUTTON
***************/
button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 47px;
  border-radius: 30px;
  text-align: center;
  font-size: 15px;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #d6d9e1;
  color: #1e2530;
  background-color: #fff;

  &.btn {
    width: 100%;
    height: 47px;
    border-radius: 30px;
    border: 1px solid #d6d9e1;
    color: #1e2530;
    background-color: #fff;
  }

  &.primary {
    color: #fff;
    background-color: #1e2530;
    border-color: #1e2530;

    &:disabled {
      cursor: default;
      background-color: #c0c4cd;
      color: #fff;
      border-color: #c0c4cd;
    }
  }

  &.link {
    border: none;
  }

  &:disabled {
    cursor: default;
    background-color: #fff;
    color: #A5ACB8;
    border-color: #D6D9E1;
  }
}

button + button {
  margin-top: 8px;
}

/* Event 상세이미지 */
.lazy-load-image-background {
  img {
    width: 100%;
  }
}

.react-slideshow-container {
  margin-right: 16px;
  margin-left: 16px;
}

.react-slideshow-wrapper {
  .images-wrap img {
    border-radius: 20px;
  }
}

.title {
  @include _tit;
}

h5 {
  font-weight: 600;
  font-size: 13px;
  color: #555D67;
}

// Label
/* Event Info -BoxItemStyled */
.dot-list {
  padding: 8px 12px;
  background: #f4f5fb;
  border-radius: 12px;

  li {
    position: relative;
    padding-left: 18px;
    font-size: 12px;
    color: #555d67;
    line-height: 1.6;

    &:before {
      content: "";
      position: absolute;
      top: 7px;
      left: 8px;
      width: 3px;
      height: 3px;
      border-radius: 100%;
      background-color: #555d67;
    }
  }
}

.box-item {
  padding-right: 16px;
  padding-left: 16px;

  h3 {
    @include _tit;
  }

  ul, ol {
    padding: 8px 12px;
    background: #f4f5fb;
    border-radius: 12px;
    margin-bottom: 24px;

    li {
      position: relative;
      padding-left: 18px;
      font-size: 12px;
      color: #555d67;
      line-height: 1.6;

      &:before {
        content: "";
        position: absolute;
        top: 7px;
        left: 8px;
        width: 3px;
        height: 3px;
        border-radius: 100%;
        background-color: #555d67;
      }
    }
  }

  ol ul {
    padding: 0px 12px;
    margin-bottom: 0px;

    li {
      list-style-type: none;
      padding-left: 0px;
    }
  }

  ol li {
    list-style-type: decimal;
    list-style-position: inside;
    padding-left: 7px;

    &:before {
      content: none;
    }
  }
}

/* Form Style */
.form-control {
  padding: 14px 16px;
  position: relative;
  width: 100%;
  height: 47px;
  border: 1px solid #D6D9E1;
  background-color: #fff;
  border-radius: 30px;
  color: #1e2530;
  font-size: 15px;
  caret-color: $primary;
  font-weight: 500;

  &:not([disabled]):not([readonly]):not(select):focus {
    border-color: $primary;
    box-shadow: none !important;
  }

  &[disabled], &[readonly] {
    background-color: #F4F5FB;
    color: #A5ACB8;
    opacity: 1;
  }

  &[readonly] {
    color: #838A93;
    font-weight:500;
  }

  & ~ .form-control {
    margin-top: 8px;
  }
}
/* Inquiry Form Style */
.inquiry-form-control {
  padding: 14px 16px;
  position: relative;
  width: 100%;
  height: 47px;
  border: 1px solid #D6D9E1;
  background-color: #fff;
  border-radius: 30px;
  color: #1e2530;
  font-size: 15px;

  &:not([disabled]):not([readonly]):not(select):focus {
    border-color: $primary;
  }

  &[disabled], &[readonly] {
    background-color: #F4F5FB;
    color: #838A93;
    opacity: 1;
  }

  &[readonly] {
    color: #838A93;
    font-weight:500;
  }

  & ~ .form-control {
    margin-top: 8px;
  }
}
/* Dropdown */
select.form-control {
  padding: 0px 16px;
  line-height: 45px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 7.75L10 12.25L14.5 7.75' stroke='%23838A93' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) center;

  &[disabled] {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 7.75L10 12.25L14.5 7.75' stroke='%23C0C4CD' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}

/* Textarea */
.form-textarea {
  position: relative;
  padding: 11px 16px;
  border: 1px solid #d6d9e1;
  background-color: #fff;
  border-radius: 16px;
  font-size: 15px;

  textarea {
    border: 0;
    width: 100%;
    background: transparent;
    font-size: 15px;
    line-height: 23px;
    word-break: keep-all;
  }

  .length {
    position: relative;
    display: block;
    color: #A5ACB8;
    text-align: right;
  }
}

/* Form UI*/
.form-group {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;


  & + .form-group {
    margin-top: 8px;
  }

  .form-control {
    flex: 1 1 auto;
    min-width: 0;
    width: 1%;

    & + button {
      margin-left: 8px
    }

    & ~ .form-control {
      margin-top: 0;
      margin-left: 8px
    }
  }

  button {
    width: auto;
    padding: 0 20px;
  }

  /* Clear 버튼 */
  .clear {
    @include _resetBtn;
    @include _icon;
    visibility: hidden;
    position: absolute;
    top: calc(50% - 10px);
    right: 13px;
    width: 20px;
    height: 20px;
    padding: 0px;
    background-image: img-url("btn_input_clear.svg");
  }
}


.inquiry-form-group {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;

  & + .inquiry-form-group {
    margin-top: 8px;
  }

  .inquiry-form-control {
    flex: 1 1 auto;
    min-width: 0;
    width: 1%;

    & + button {
      margin-left: 8px
    }

    & ~ .inquiry-form-control {
      margin-top: 0;
      margin-left: 8px
    }
  }

  button {
    width: auto;
    padding: 0 20px;
  }

  /* Clear 버튼 */
  .clear {
    @include _resetBtn;
    @include _icon;
    visibility: hidden;
    position: absolute;
    top: calc(50% - 10px);
    right: 13px;
    width: 20px;
    height: 20px;
    padding: 0px;
    background-image: img-url("btn_input_clear.svg");
  }
}





/* Error Message */
.error {
  .form-control, &.form-textarea {
    border-color: #FF3D45;
  }

  .length {
    color: #FF3D45;
  }
}

.error-message {
  padding: 2px 0 0 16px;
  display: flex;
  max-width: 100%;
  min-width: 1px;
  flex: 1 1 auto;
  font-size: 12px;
  color: #FF3D45;
}

/***************
    기본 라디오
***************/
.radio-button:checked,
.radio-button-disabled:checked,
.radio-button:not(:checked),
.radio-button-disabled:not(:checked) {
  position: absolute;
  left: -9999rem;
}

.radio-button:checked + label,
.radio-button-disabled:checked + label,
.radio-button:not(:checked) + label,
.radio-button-disabled:not(:checked) + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  color: #555D67;
}


.radio-button:checked + label:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #000000;
}

.radio-button-disabled:checked + label:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #cccccc;

}

.radio-button:not(:checked) + label:before,
.radio-button-disabled:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid #d6d9e1;
  background: #ffffff;
}

.radio-button:checked + label:after,
.radio-button-disabled:checked + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  position: absolute;
  top: 6px;
  left: 8px;
  border-radius: 100%;
}

.radio-button:not(:checked) + label:after,
.radio-button-disabled:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
}

.radio-button:not(:checked) + label:after {
  opacity: 0;
}

.radio-button:checked + label:after {
  opacity: 1;
}


/*******************
    체크박스
********************/
.checkbox {
  display: none;
  position: absolute;
}

.checkbox + label:after {
  @include _icon;
  position: absolute;
  width: 12px;
  height: 12px;
  top: 4px;
  left: 6px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5L5 9L11 3' stroke='%23A5ACB8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.checkbox + label {
  display: inline-block;
  padding-left: 30px;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  color: #555D67;
  cursor: pointer;
}

.checkbox + label:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 2px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #E5E8F0;
}

/* Checked */
.checkbox:checked + label:before {
  background: #1E2530;
}

.checkbox:checked + label:after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5L5 9L11 3' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.checkbox:checked + label {
  color: #1E2530
}

/*공통 - Swiper Slide Spect */
.swiper-slide {
  img {
    display: block;
    width: 100%;
    height: auto;
    // object-fit: cover;
  }
}

.swiper-pagination {
  position: absolute;
  padding: 0 30px;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  text-align: center;
  font-size: 15px;
  line-height: 1;

  &.swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%;
    color: #d6d9e1;

    .swiper-pagination-current {
      color: #060606;
      font-weight: 600;
    }
  }

  &.swiper-pagination-bullets {
    bottom: 0px;
    left: 0;
    width: 100%;
  }

  .swiper-pagination-bullet {
    display: inline-block;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #d6d9e1;

    + .swiper-pagination-bullet {
      margin: 0 2px;
    }
  }

  .swiper-pagination-bullet-active {
    background: $primary;
  }
}

.slide-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 24px;

  .fraction {
    display: flex;
    justify-content: center;
    font-size: 15px;

    color: #D6D9E1;

    span {
      width: 20px;
      display: flex;
    }

    .curr {
      font-weight: 600;
      color: #060606;
      justify-content: right;
      margin: 0 3px 0 4px;
    }

    .total {
      justify-content: left;
      margin: 0 4px 0 3px;
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  display: inline-flex;
  width: 32px;
  height: 32px;
  z-index: 10;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #d6d9e1;
  border-radius: 20px;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  @include _icon;
}

.swiper-button-prev::after {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.2426 7.75736L9 12L13.2426 16.2426' stroke='%23555D67' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.swiper-button-next::after {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7574 7.75736L15 12L10.7574 16.2426' stroke='%23555D67' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

/* Comp - BannerSlide */
.banner-slider {
  padding: 0 16px 0px 16px !important;

  img {
    border-radius: 20px;
  }

  .swiper-pagination.swiper-pagination-bullets {
    bottom: 12px
  }
}

/* Comp - ProductSlide */
.product-slider {
  padding: 16px 20px 24px !important;

  .swiper-slide {
    width: 95px;
    height: 95px;
    margin: 0 auto;
  }
}

/* event 23 : 랭킹 */
.rank-list {
  margin: 0 16px;

  li {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 15px 16px;
    font-size: 15px;
    @include _boxShdow;

    + li {
      margin-top: 16px;
    }

    .rank-text {
      flex: 1 1 auto;
      min-width: 0;
      width: 1%;
      font-weight: 600;
    }

    strong {
      font-weight: 600;
    }

    .rank-text:before {
      @include _icon;
      margin-right: 8px;
      background-image: img-url("icon_rank_gold.svg");
    }

    /* 랭크2위 */
    + li .rank-text:before {
      background-image: img-url("icon_rank_silver.svg");
    }

    /* 랭크3위 */
    + li + li .rank-text:before {
      background-image: img-url("icon_rank_bronze.svg");
    }
  }
}

/* event 23 : 선택 응모 */
.choice-check {
  color: #1e2530;
  font-weight: 600;
  font-size: 15px;
  align-items: center;

  label {
    @include _boxShdow;
    padding: 16px;
  }

  span {
    color: #1e2530;
  }

  .radio-button:checked + label:before,
  .radio-button:not(:checked) + label:before,
  .radio-button:checked + label:after,
  .radio-button:not(:checked) + label:after {
    left: 16px;
    top: calc(50% - 10px);
    /*label text millde */
  }

  input[type="radio"]:checked + label {
    border-color: #555d67;
  }

  + .choice-check {
    margin-top: 16px;
  }
}

/* 공통 Event BOX */
.card-box {
  @include _boxShdow;
  padding: 16px
}

/*event 28 : 배달 신장형*/
.event-progress {
  padding: 10px 0px 26px 0px;

  ul {
    position: relative;
    display: flex;
    justify-content: space-between;

    li {
      position: relative;
      flex: 1;

      span {
        display: inline-block;
        position: absolute;
        padding-top: 14px;
        color: #838a93;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;

        &.current {
          color: #1e2530;
        }
      }
    }

    li:last-child {
      flex: inherit;

      span {
        right: 0;
      }
    }

    li:not(:first-child):not(:last-child) span {
      left: -10px;
    }
  }
}

/* 공통- Top버튼 */
.scroll-top {
  button {
    display: inline-flex;
    position: fixed;
    z-index: 1;
    width: 48px;
    height: 48px;
    bottom: 16px;
    right: 16px;
    font-size: 0;
    border-radius: 100%;
    background: #fff;
    border: none;
    box-shadow: 0px 2px 4px rgba(6, 6, 6, 0.08);

    &:after {
      @include _icon;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.4853 11.4853L12 3L3.51472 11.4853' stroke='%23060606' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cline x1='12' y1='4' x2='12' y2='21' stroke='%23060606' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
    }
  }
}

/* GS event 08 : 스탬프 적립 */
/* 매장 주소 (pop_success_confirm) */
.notice {
  position: relative;
  font-size: 13px;
  color: #A5ACB8;

  &:before {
    @include _icon;
    margin: -2px 8px 0 0;
    width: 20px;
    height: 20px;
    background-image: img-url("icon_noti.svg");
  }
}

.soldout {
  @include flex-m;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 20px;
  font-weight: 700;
  color: #fff;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(6, 6, 6, 0.4);
    border-radius: 12px;
  }
}

.info_list {
  width: 100%;
  margin: 0 auto;
  font-size: 13px;
  line-height: 18px;
  color: #555D67;

  dt {
    float: left;
    min-width: 104px;
    color: #060606;
    font-weight: 600;
  }

  dd {
    overflow: hidden;
    margin-bottom: 8px;
    word-break: keep-all;
    word-wrap: break-word;
    overflow-wrap: break-word;

    &:after {
      display: block;
      content: '';
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/*  Fresh - event 06 : Tip  */
.float-tip {
  position: absolute;
  top: 90%;
  left: 50%;
  padding: 2px 12px 0px;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  line-height: 32px;
  border-radius: 8px;
  white-space: nowrap;
  transform: translateX(-50%);
  background: $accent;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: -5px;
    left: 50%;
    margin-left: -5px;
    width: 13px;
    height: 14px;
    background: $accent;
    border-radius: 2px;
    transform: rotate(45deg) skew(6deg, 6deg);
  }
}

/* Title + 더보기 */
.title-area {
  display: flex;
  align-items: center;
  padding: 24px 16px 16px;

  h3 {
    padding: 0px;
    margin: 0px;
    flex: 1 1 auto;
    min-width: 0;
  }

  .link-more {
    display: inline-block;
    font-size: 13px;
    color: #060606;
    padding: 10px 16px;
    margin: -10px -16px;
    cursor: pointer;

    &:after {
      @include _icon;
      margin: -3px 0px 0 1px;
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.75736 12.2426L11 8L6.75736 3.75736' stroke='%23060606' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }
}

/************
    고객센터 메인
*************/
.chip {
  @include _resetBtn ;
  padding: 0;
  font-size: 13px;
  color: #555D67;
  background: transparent;

  input[type=radio], input[type=checkbox] {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  label {
    position: relative;
    display: inline-block;
    border: 1px solid #D6D9E1;
    padding: 2px 12px 0;
    border-radius: 20px;
    line-height: 26px;

    color: #838A93;
  }

  input[type="radio"]:checked + label, input[type="checkbox"]:checked + label {
    border-color: #1e2530;
    color: #1e2530;
  }
}

.notice-slide {
  .swiper-slide {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

/* 공지사항 */
.notice-list, .inquiry-list {
  li {
    position: relative;
    cursor: pointer;
    padding: 16px 54px 16px 16px;

    strong {
      @include _ellipsis2;
      font-weight: 600;
      font-size: 15px;
      line-height: 23px;
    }

    span {
      display: block;
      margin-top: 2px;
      font-size: 13px;
      color: #838A93;
    }

    // 날짜
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 16px;
      right: 16px;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7574 16.2426L15 12L10.7574 7.75736' stroke='%23838A93' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }

    //> a  {display:block; padding: 16px;}
  }
}

/* FAQ */
.brand-category {
  display: table;
  white-space: nowrap;

  li {
    display: table-cell;
    padding: 8px 0;
  }

  .chip {
    font-size: 15px;
    color: #555D67;
    background: transparent;

    label {
      padding: 1px 16px 0;
      border-radius: 30px;
      line-height: 34px;
      height: 35px;
      border: none;
    }

    input[type="radio"]:checked + label, input[type="checkbox"]:checked + label {
      background: #1e2530;
      color: #fff;
    }
  }
}

/* FAQ List(Accordion) */
.faq {
  margin-bottom: 24px;

  .accordion__button {
    padding-left: 20px;
    @include _ellipsis2;
    word-break: keep-all;
  }

  .accordion__panel {
    line-height: 1.7;
    background-color: #F4F5FB;
    white-space: break-spaces;
  }

  .accordion__button:before {
    display: inline-block;
    content: 'Q.';
    position: absolute;
    top: 0px;
    left: 0px;
    color: #00D4EA;
  }

  .faq-root {
    position: relative;
    margin-top: 12px;
    text-align: right;
    color: #060606;

    &:before {
      content: ' ';
      display: inline-block;
      vertical-align: middle;
      width: 3px;
      height: 3px;
      border-radius: 100%;
      background-color: #060606;
      margin-right: 6px
    }
  }
}

/* 1:1문의 목록*/
.inquiry-list li {
  padding-right: 16px;

  span {
    margin-top: 4px;
  }

  &:after {
    display: none;
  }

  //em:after {content: '|'; color:#E5E8F0; display: inline-block; margin:0 8px; font-size: 12px}
  .tag {
    background-color: #1E2530;
    margin-right: 8px;
    font-size: 11px;
    line-height: 17px;
  }

  //기본:답변완료
}

/* 1:1 문의 */
.nav-scroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 47px 0 16px;

  &:before, &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    height: 100%;
  }

  &:before {
    right: 47px;
    width: 40px;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &:after {
    right: 0px;
    width: 48px;
    background: #fff;
  }

  .brand-category:before {
    content: " ";
    z-index: 1;
    display: inline-block;
    position: absolute;
    top: calc(50% - 8px);
    right: 48px;
    width: 1px;
    height: 16px;
    background: #E5E8F0;
  }

  .brand-category li:last-child {
    padding-right: 32px;
  }
}

.nav-scroll.unfold {
  overflow: hidden;
  padding: 0 48px 0 16px;

  .brand-category {
    padding: 2px 0;
    white-space: normal;
    margin-right: 0px !important;

    li {
      display: inline-block;
      padding: 6px 0;
    }
  }

  &:before, .brand-category:before {
    display: none
  }

  & ~ .btn-fold {
    transform: rotate(-180deg)
  }
}

/* 1:1 문의 등록 라디오 버튼*/

.inquiry-radio-button:checked,
.inquiry-radio-button-disabled:checked,
.inquiry-radio-button:not(:checked),
.inquiry-radio-button-disabled:not(:checked) {
  position: absolute;
  left: -9999rem;

}

.inquiry-radio-button:checked + label,
.inquiry-radio-button:not(:checked) + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  color: #1E2530;

}

.inquiry-radio-button-disabled:checked + label,
.inquiry-radio-button-disabled:not(:checked) + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  color: #A5ACB8;

}


.inquiry-radio-button:checked + label:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #000000;

}

.inquiry-radio-button-disabled:checked + label:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #cccccc;


}

.inquiry-radio-button:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid #d6d9e1;
  background: #ffffff;
}

.inquiry-radio-button-disabled:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid #f4f5fb;
  background: #ffffff;
}

.inquiry-radio-button:checked + label:after,
.inquiry-radio-button-disabled:checked + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  position: absolute;
  top: 6px;
  left: 8px;
  border-radius: 100%;
}

.inquiry-radio-button:not(:checked) + label:after,
.inquiry-radio-button-disabled:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
}

.inquiry-radio-button:not(:checked) + label:after {
  opacity: 0;
}

.inquiry-radio-button:checked + label:after {
  opacity: 1;
}


/* 아이콘버튼-열기/접기 */
.btn-fold {
  @include _resetBtn;
  position: absolute;
  z-index: 2;
  top: 9px;
  right: 8px;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d6d9e1;
  border-radius: 20px;
  font-size: 0;
  transition: all ease .4s 0s;

  &:before {
    @include _icon;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.75736 10.7574L12 15L16.2426 10.7574' stroke='%23555D67' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}

/* 아이콘버튼-삭제 */
.btn-del {
  @include _resetBtn;
  font-size: 0;
  padding: 6px;

  &::before {
    @include _icon;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #838A93;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.9796 13.9669L9.96338 9.98315L5.9796 5.99938' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M13.9628 13.9716L9.979 9.98779L13.9628 6.00402' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    //background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.9796 13.9669L9.96338 9.98315L5.9796 5.99938' stroke='%23060606' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M13.9628 13.9716L9.979 9.98779L13.9628 6.00402' stroke='%23060606' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}

.dim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(30, 37, 48, 0.3);
  animation-duration: .2s;
  animation-fill-mode: backwards;
}

.fadeIn {
  animation-name: fadeIn;
}

.fadeOut {
  animation-name: fadeOut;
}

/* 약관 Accordion */
.terms-accordion {
  .accordion__button {
    padding: 8px 34px 8px 16px;

    color: #555d67;

    &:after {
      top: 12px;
      right: 16px;
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.65824 6.75735L8.90088 11L13.1435 6.75735' stroke='%23C0C4CD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }

  .accordion__panel {
    padding: 8px 0px 8px 10px;
    margin-bottom: 16px;
  }
}


/* dot-single-list */
.dot-single-list {
  position: relative;
  padding-left: 18px;
  font-size: 12px;
  color: #555d67;
  line-height: 1.6;

  &:before {
    content: "";
    position: absolute;
    top: 7px;
    left: 8px;
    width: 3px;
    height: 3px;
    border-radius: 100%;
    background-color: #555d67;
  }
}

.marginLeft6 {
  margin-left: 6px;
}

.marginLeftRight20 {
  margin-left: 20px;
  margin-right: 20px;
}