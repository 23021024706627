@import "variables";
.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  @include flex-m;
  flex-wrap: wrap;
  margin: 0 16px;
  position: relative;

  .react-tabs__tab {
    flex: 1;
    position: relative;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    color: #838A93;
    line-height: 23px;
    padding: 12px 0;
    z-index: 2;
  }

  .react-tabs__tab--selected {  color: #1E2530; font-weight: 700; }
  .react-tabs__tab--selected ~ .indicator {left: 0;}
  /* 기본탭 2개 기준 */
  .react-tabs__tab--selected:nth-child(2) ~ .indicator { left: 50%;}
  .indicator {
    width: 50%;
    height: 4px;
    position: absolute;
    bottom: 0;
    background: #1E2530;
    transition: left 0.3s ease 0s;
    z-index: 1;
  }

  .react-tabs__tab--selected:nth-child(1) ~ .indicator2 {left: 0%;}
  .react-tabs__tab--selected:nth-child(2) ~ .indicator2 {left: 34%;}
  .react-tabs__tab--selected:nth-child(3) ~ .indicator2 {left: 67%;}

  .indicator2 {
    width: 33%;
    height: 4px;
    position: absolute;
    bottom: 0;
    background: #1E2530;
    transition: left 0.3s ease 0s;
    z-index: 1;
  }


  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }

  .react-tabs__tab:focus {
    outline: none;
  }

  .react-tabs__tab:focus:after {

  }

  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }

}



.react-tabs__tab-list_v1 {
  @include flex-m;
  flex-wrap: wrap;
  margin: 8px 16px 16px;
  background: #E5E8F0;
  border-radius: 40px;
  border: 4px solid #E5E8F0;
  position: relative;

  .react-tabs__tab {
    flex: 1;
    position: relative;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    color:#555D67;
    border-radius: 40px;
    line-height: 40px;
    z-index: 2;
  }

  .react-tabs__tab--selected {  color: #060606; /*background: #fff;*/ }
  .react-tabs__tab--selected ~ .indicator {
    left: 0;
  }
  /* 기본탭 2개 기준 */
  .react-tabs__tab--selected:nth-child(2) ~ .indicator { left: 50%;}
  .indicator {
    width: 50%;
    height: 40px;
    position: absolute;
    bottom: 0;
    background: #fff;
    transition: left 0.3s ease 0s;
    border-radius: 40px;
    z-index: 1;
  }

  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }

  .react-tabs__tab:focus {
    outline: none;
  }

  .react-tabs__tab:focus:after {

  }

  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }
}

